.ds-table-row {
    
    .card {
        
        .content {
            padding: 0;
        }
    }

    .ds-data-table {
        margin-bottom: 20px;
        background-color: $white-bg;
        box-shadow: 0 0px 1px rgba(0, 0, 0, 0.2);
    
        .data-table {
            width: 100%;
            border-collapse: collapse;
            
            .ds-tr {
                padding: 0 20px;
            }
    
            .ds-thead {
                
                .ds-th {
                    $color-black: $black-color;
                    vertical-align: top;
                    padding: 15px;
                    border-bottom: 1px solid $black-color;
                    text-transform: uppercase;
                    font-weight: normal;
    
                    .table-filters {
    
                        .form-control {
                            border-radius: 2px;
                            padding: 0px 8px;
                            height: 24px;
                            box-shadow: none;
                            font-size: 12px;
                            margin-top: 5px;
                        }
                    }
                }
            }
    
            .ds-tbody {
    
                .ds-tr {
                    max-height: 40px;
                    min-height: 40px;
                    border-top: 1px solid $light-gray;
    
                    .ds-td {
                        font-size: 12px;
                        padding: 0px 15px;
                        vertical-align: middle;
                    }
                }
            }
        }
    
        .table-bottom {
    
            .pagination {
                width: 100%;
                padding: 10px 0;
                text-align: right;
                border-radius: 0;
                margin-bottom: 0;
                justify-content: flex-end;
                border-top: 1px solid $black-color;
                border-bottom: 1px solid $black-color;
    
                .btns {
                    margin: 0 5px;
                    padding: 0 10px;
    
                    &.--previous {
                        
                        &::before {
                            font: normal normal normal 14px/1 FontAwesome;
                            content: "\f053";
                            font-size: 10px;
                        }
                    }
    
                    &.--first {
                        
                        &::before {
                            font: normal normal normal 14px/1 FontAwesome;
                            content: "\f100";
                            font-size: 14px;
                        }
                    }
    
                    &.--next {
    
                        &::before {
                            font: normal normal normal 14px/1 FontAwesome;
                            content: "\f054";
                            font-size: 10px;
                        }
                    }
    
                    &.--last {
                        padding: 0 10px;
    
                        &::before {
                            font: normal normal normal 14px/1 FontAwesome;
                            content: "\f101";
                            font-size: 14px;
                        }
                    }
    
                }
        
                input,
                select {
                    min-width: 100px;
                    text-align: center;
                    border-radius: 0;
                    margin: 0px 10px;
                    border: 1px solid $light-gray;
                    background-color: $white-bg;
                    height: 20px;
                }
    
                .table-rows-info {
                    float: left;
                    padding: 0 15px;
                }
            }
    
            .-loading,
            .ds-noData {
                background: $white-color;
                height: 100%;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: top;
    
                
            }
    
            .-loading {
                background-image: url("../../assets/img/placeholders/datatable-placeholder.gif");
                background-position-x: 1px;
    
                .-loading-inner {
                    display: none;
                }
            }
            
            .ds-noData {
                background-image: url("../../assets/img/placeholders/datatable-placeholder.png");
                font-size: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-size: cover;
            }
        }
    }
}

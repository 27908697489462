//============================================================================================
// chips
//============================================================================================
.ds-chips {

    .chips__content {

        display: inline-block;
        color: $white-color;
        border-radius: 4px;
        padding: 2px 8px;
        margin-right: 5px;
        margin-top: 5px;

        &.warning {
            background-color: $color-orange;
        }

        &.success {
            background-color: $color-green;
        }

        &.printing {
            background-color: $blue-color-top;
        }

        &.danger {
            background-color: $color-red;
        }
    }   
}

.ds-loading-spinner {
    width: 100%;
    padding: 20px;

    &.data-table-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
    }
}
.login-page {

  .login-icon {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: auto;
    margin-bottom: 30px;
    margin-top: -95px;
    background: $color-azure;
    padding: 20px;
    text-align: center;
    box-shadow: 0 10px 14px -13px rgba(40, 40, 40, 0.4);

    img {
      height: 100%;
    }
  }
}

.theme-dental-admin {
  .btn-primary {
    @include btn-styles($dental-admin-color, $dental-admin-states-color);
  }

  .checkbox input[type="checkbox"]:checked + label::after {
    color: $secondary-color;
  }

  a {
    color: $dental-admin-color;

    &:hover,
    &:focus {
      color: $dental-admin-states-color;
      text-decoration: none;
    }
  }

  .navbar-default {
    background-color: $secondary-color;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ds-scan-details {
    .ds-patient-info {
      .patient-info__userinfo {
        .userinfo__icon {
          border: 1px solid $secondary-color;
          > i  {
            font-size: 70px;
            color: $secondary-color;
          }
        }
      }
    }

    .scan-details__history {
      .scan-details__content {
        .scan-history__profilepic {
          
          border: 1px solid $secondary-color;
          > i  {
            color: $secondary-color;
          }
        }
      }

      .scan-history__content {
        .scan-history__heading {
          color: $secondary-color;
        }

        .scan-history__tasks {
          .scan-history__tasks--li {
            .tasks__li {
              .assigned-username {
                color: $secondary-color;
              }
            }
          }
        }
      }
    }
  }
}
.ds-scan-details {

    .ds-patient-info {

        [class*="col-"] {
            border-left: 1px solid $light-gray;

            &:first-child {
                border-left: none;
            }
        }

        .scan-details__heading {

            > h5 {
                font-size: 17px;
            }
        }

        .scan-details__content {
            font-size: 13px;
            color: $dark-gray;
            margin-bottom: 5px;
        }

        .patient-info__userinfo {

            [class*="col-"] {
                border-left: none;
            }

            .userinfo__icon {
                height: 80px;
                width: 80px;
                overflow: hidden;
                border-radius: 50%;
                font-size: 70px;
                border: 1px solid $color-azure;
                color: $color-azure;

                i {
                    width: 100%;
                }
            }

            .scan-details__heading {

                > h5 {
                    
                    > strong {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .scan-details__history {

        .scan-details__content {
            margin-bottom: 40px;

            .scan-history__profilepic {
                float: left;
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 50%;
                margin-right: 30px;
                overflow: hidden;
                border: 1px solid $color-azure;
                color: $color-azure;

                i {
                    width: 100%;
                    font-size: 34px;
                    padding: 7px 5px;
                
                }
            }
    
            .scan-history__content {
                width: calc(100% - 90px);
                float: left;
    
                .scan-history__heading {
                    font-size: 16px;
                    font-weight: 600;
                    color: $color-azure;
                }
    
                .scan-history__tasks {
    
                    .scan-history__tasks--li {
                        padding: 0;
                        margin: 0;
    
                        .tasks__li {
                            font-size: 12px;
                            color: $dark-gray;
                            position: relative;
                            
                            .task__status {
                                font-size: 14px;
                                color: $black-color;
                                margin: 0 5px;
    
                                &:first-child {
                                    margin-left: 0;
                                }
                            }
    
                            .assigned-username {
                                font-size: 14px;
                                color: $color-azure;
                            }
    
                            list-style-type: none;
                            margin-left: 5px;
                            
                            > div {
                                padding-left: 30px;
                                padding-bottom: 10px;
                                position: relative;
                                border-left: 1px solid $medium-gray;
                                
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: -1px;
                                    bottom: 50%;
                                    width: 1.50em;
                                    border: 1px solid $medium-gray;
                                    border-top: 0 none transparent;
                                    border-right: 0 none transparent;
                                }
                            }
    
                            .status__date-time {
                                display: block
                            }

                            &.task-assigned {

                                > div {
                                    padding-top: 20px;
                                }
                            }
    
                            &:first-child {
                                padding-top: 10px;
                            }

                            &:last-child {

                                > div {
                                    border-left: 1px solid transparent;
                                }
                                
                            }
                        }

                        .task__comments {
                            list-style-type: none;

                            .task__users-comment {
                                padding: 8px 35px 8px 12px;
                                margin: 0;
                                border: 1px solid #dedede;
                                border-radius: 0px 4px 4px 20px;
                                min-height: 32px;
                                transition: background-color 1s ease;
                                font-size: 14px;
                                color: #333333;
                                position: relative;
                                
                                .dlt-commnet-btn {
                                    position: absolute;
                                    right: 5px;
                                    top: 5px;
                                    color: $color-azure;
                                    border: none;
                                    font-size: 14px;
                                }

                                &.comment-deleted {
                                    color: $dark-gray;
                                }
                            }

                            .status__date-time {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .scan-details__comment {
    
            .action-btns {

                .comment__actions {
                    margin-right: 10px;
                    display: inline-block;
                    min-width: 200px;

                    > small {
                        display: block;
                        font-size: 12px;
                        color: $dark-gray;
                        margin-bottom: 5px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                    
                    .actions_dropdown {


                        .css-kj6f9i-menu {
                            max-height: 150px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}